import React from 'react';
import { Container } from 'reactstrap';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import defaultImg from '../img/defaultImg.jpg';

export default function Template({ data }) {
  const { createdAt, title, blog, author, blogPreviewPhoto, dateCreated } = data.contentfulBlogPost;

  // If preview photo isn't uploaded, defaultimg is imported
  var previewPhoto = defaultImg;
  if (blogPreviewPhoto) {
    previewPhoto = blogPreviewPhoto.file.url;
  }

  return (
    <Layout nav="colored-nav">
      <Helmet title={`Glory Reborn Blog - ${title}`}>
        {data.site.siteMetadata.disqus && (
          <script id="dsq-count-scr" src="//gatsby-starter-blog.disqus.com/count.js" async />
        )}
        {data.site.siteMetadata.disqus && (
          <script>{`(function() {
          var d = document, s = d.createElement('script');
          s.src = 'https://${data.site.siteMetadata.disqus}.disqus.com/embed.js';
          s.setAttribute('data-timestamp', +new Date());
          (d.head || d.body).appendChild(s);
          })();`}</script>
        )}
      </Helmet>
      <section className="mt80">
        <img src={previewPhoto} alt="" className="w-100 blog-featured-photo" />
      </section>
      <section>
        <div className="container">
          <div className="row d-flex justify-content-center mt-5">
            <div className="col-10">
              <div className="row">
                <div className="col-md-3">
                  {author.name}
                  <br />
                  Glory Reborn Founder
                  <br />
                  {new Date(dateCreated || createdAt).toDateString()}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <h1 className="f60 mt-5 mb-5">{title}</h1>
                </div>
                <div className="col-md-12 mb-5">
                  <div
                    className="blog-post-div f24 dark-gray d-flex flex-column align-items-center"
                    dangerouslySetInnerHTML={{ __html: blog.childMarkdownRemark.html }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {data.site.siteMetadata.disqus && (
        <Container>
          <hr />
          <div id="disqus_thread" />
        </Container>
      )}
    </Layout>
  );
}

export const pageQuery = graphql`
  query SingleByPath($slug: String) {
    site {
      siteMetadata {
        disqus
      }
    }

    contentfulBlogPost(slug: { eq: $slug }) {
      dateCreated
      author {
        name
      }
      createdAt
      title
      blog {
        blog
        childMarkdownRemark {
          html
        }
      }
      blogPreviewPhoto {
        file {
          url
        }
      }
    }
  }
`;
